<template>
  <div class="deposit" v-if="hasPerm('payments.view_deposit')">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="deposit">
      <page-header title="Dépôts" icon="fas fa-money-bill-alt" :links="getLinks()"></page-header>
      <div class="sub-header">
        <tabs-bar
          :tabs="tabs"
          :active="activeTab"
          :enabled="true"
          secondary
          @change="tabChanged($event)"
        ></tabs-bar>
      </div>
      <div ref="printMe">
        <div class="sub-header">
          <b-row>
            <b-col>
              <div>
                <b>
                  {{ deposit.depositOn | dateToString }} <span v-if="deposit.number">- N°{{ deposit.number }}</span>
                </b>
              </div>
              <div v-if="deposit.comments">
                {{ deposit.comments }}
              </div>
              <b-checkbox
                v-if="activeTab.name !== 'analytics'"
                v-model="showDetail"
                :value="true"
                :unchecked-value="false"
                @change="onDetailsClick"
                class="inline no-print"
              >
                Affichage détaillé
              </b-checkbox>
              <b-checkbox
                v-if="activeTab.name !== 'payments'"
                v-model="areAnalyticsGrouped"
                :value="true"
                :unchecked-value="false"
                class="inline no-print"
                @change="onAnalyticsGroupedClick"
              >
                Affichage par groupes
              </b-checkbox>
              <div class="hide-here" v-if="activeTab.name !== 'payments'">
                <b v-if="areAnalyticsGrouped">Affichage par groupes</b>
                <b v-else>Affichage par compte</b>
              </div>
            </b-col>
            <b-col class="text-center">
              <div><b>{{ paymentModeNames }}</b></div>
              <b><counter-label :counter="payments.length" label="paiement"></counter-label></b>
              <div v-if="expenses.length">
                <b><counter-label :counter="expenses.length" label="dépense"></counter-label></b>
              </div>
              <div v-if="refunds.length">
                <b><counter-label :counter="refunds.length" label="remboursement"></counter-label></b>
              </div>
            </b-col>
            <b-col class="text-right">
              <b>{{ depositTotalNoReturned|currency }}</b>
              <div v-if="hasReturned" class="small-text bold">
                refusé: {{ sumReturned|currency }}<br />
                montant initial: {{ depositTotal|currency }}
              </div>
            </b-col>
          </b-row>
        </div>
        <div ref="excelTable">
          <div v-if="activeTab.name !== 'analytics'">
            <table class="table table-striped">
              <tr v-for="payment in payments" :key="payment.id" class="hover-me">
                <td>
                  <div v-if="payment.paymentDate">
                    {{ payment.paymentDate | dateToString }}
                  </div>
                  <div v-else>
                    {{ payment.createdOn | dateToString }}
                  </div>
                  <div v-if="payment.returned" class="badge badge-danger">
                    {{ payment.getReturnedStatus() }}
                  </div>
                  <div class="no-print no-excel" v-if="canDeletePayment">
                    <a v-if="false" @click.prevent="editPayment(payment)" href class="show-on-hover">
                      <i class="fa fa-edit"></i> Modifier
                    </a>
                    <a v-if="!payment.returned" @click.prevent="deletePayment(payment)" href class="show-on-hover">
                      <i class="fa fa-hand-point-left"></i> Refus
                    </a>
                  </div>
                </td>
                <td>
                  <span v-if="payment.emitter">{{ payment.emitter }} pour </span>
                  <a
                    @click.prevent="showEntitySidebar(payment.entity)"
                    :href="getEntityLink(payment.entity)"
                  >
                    {{ payment.entity.name }}
                  </a>
                </td>
                <td v-if="showDetail">
                  <span v-for="invoice of paymentInvoices(payment)" :key="invoice.id">
                    <invoice-badge :invoice="invoice"></invoice-badge>
                  </span>
                </td>
                <td v-if="showDetail">
                  <analytics-detail-view show-general-account :analytics="payment.analytics"></analytics-detail-view>
                </td>
                <td>
                  <div>
                    <span v-if="paymentModes.length > 1">{{ payment.paymentMode.name }}</span>
                    {{ payment.bankName }} {{ payment.bankNumber }}
                  </div>
                  <div v-if="payment.comments" class="comments">{{ payment.comments }}</div>
                </td>
                <td class="number">{{ payment.amount|currency }}</td>
              </tr>
              <tr v-for="expense in expenses" :key="expense.id">
                <td>
                  {{ expense.getDate() | dateToString }}
                </td>
                <td>
                  {{ expense.label }}
                </td>
                <td v-if="showDetail"></td>
                <td v-if="showDetail">
                  <analytics-detail-view :analytics="expense.analytics"></analytics-detail-view>
                </td>
                <td>
                  Dépense
                  <div>
                    {{ expense.paymentMode.name }} {{ expense.bankName }} {{ expense.bankNumber }}
                  </div>
                </td>
                <td class="number">{{ -expense.amount|currency }}</td>
              </tr>
              <tr v-for="refund in refunds" :key="refund.id">
                <td>
                  {{ refund.createdOn | dateToString }}
                </td>
                <td>
                  {{ refund.entity.name }}
                </td>
                <td v-if="showDetail"></td>
                <td v-if="showDetail">
                  <analytics-detail-view :analytics="refund.analytics"></analytics-detail-view>
                </td>
                <td>
                  Remboursement
                  <div>
                    {{ refund.refundMode.name }} {{ refund.bankName }} {{ refund.bankNumber }}
                  </div>
                </td>
                <td class="number">{{ -refund.amount|currency }}</td>
              </tr>
            </table>
          </div>
          <hr v-if="activeTab.name === 'all'" />
          <div v-if="activeTab.name !== 'payments'">
            <analytic-accounts-table :items="analytics" :grouped="areAnalyticsGrouped">
            </analytic-accounts-table>
          </div>
        </div>
      </div>
      <div v-if="editedPayment">
        <edit-payment :payment="editedPayment" @hidden="onEditPaymentHidden" @done="onRefresh">
        </edit-payment>
      </div>
      <div v-if="deletedPayment">
        <delete-payment :payment="deletedPayment" @hidden="onDeletePaymentHidden" @done="onRefresh">
        </delete-payment>
      </div>
      <confirm-modal
        name="delete-deposit"
        title="Suppression du dépôt"
        text="Voulez-vous supprimer ce dépôt? Les paiements seront conservées et à déposer à nouveau"
        @confirmed="deleteDeposit(depositId)"
      ></confirm-modal>
      <change-deposit-modal
        id="bv-change-deposit-modal"
        :deposit="deposit"
        @update="deposit = $event.deposit"
      ></change-deposit-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel'
import TabsBar from '@/components/Controls/TabsBar'
import ChangeDepositModal from '@/components/Deposits/ChangeDepositModal'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import PageHeader from '@/components/Layout/PageHeader'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import EditPayment from '@/components/Payments/EditPayment'
import DeletePayment from '@/components/Payments/DeletePayment'
import AnalyticAccountsTable from '@/components/Accounting/AnalyticAccountsTable'
import AnalyticsDetailView from '@/components/Accounting/AnalyticsDetailView'
import router from '@/router'
import { TabItem } from '@/types/tabs'
import { makeExpense } from '@/types/cash'
import { makePayment, makeDeposit, sumPayments, makeRefund } from '@/types/payments'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'
import { compareNumbers, compareStrings } from '@/utils/sorting'
import { distinct } from '@/utils/arrays'

const depositAnalyticsGroupedStorageKey = 'deposit-analytics-grouped'
const depositShowDetailStorageKey = 'deposit-show-detail'

export default {
  name: 'Deposit',
  mixins: [BackendMixin],
  components: {
    ChangeDepositModal,
    ConfirmModal,
    AnalyticsDetailView,
    AnalyticAccountsTable,
    LoadingGif,
    CounterLabel,
    DeletePayment,
    EditPayment,
    PageHeader,
    InvoiceBadge,
    TabsBar,
  },
  props: {
    depositId: [String, Number],
  },
  data() {
    return {
      loadingName: 'deposit-detail',
      deposit: null,
      payments: [],
      expenses: [],
      refunds: [],
      areAnalyticsGrouped: true,
      tabs: [
        new TabItem('payments', 'Détail', 'fa fa-list'),
        new TabItem('analytics', 'Synthèse', 'fas fa-list-ol'),
        new TabItem('all', 'Tout', 'fa fa-file-text')
      ],
      activeTab: null,
      editedPayment: null,
      deletedPayment: null,
      depositDocConfig: false,
      showDetail: true,
      includedReturned: true,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    depositId: function(newValue) { this.loadData(newValue) },
  },
  computed: {
    analytics() {
      const analyticsMap = new Map()
      for (const items of [this.payments, this.expenses, this.refunds]) {
        for (const item of items) {
          for (const analytic of item.analytics) {
            const key = analytic.getKey()
            const amount = analytic.amount
            if (analyticsMap.has(key)) {
              const elt = analyticsMap.get(key)
              elt.amount += amount
            } else {
              const elt = analytic.clone()
              analyticsMap.set(key, elt)
            }
          }
        }
      }
      return Array.from(analyticsMap.values()).sort(
        (elt1, elt2) => {
          let value = compareNumbers(elt1.schoolYear.startYear, elt2.schoolYear.startYear)
          if (value === 0) {
            value = compareNumbers(elt1.analyticAccount.order, elt2.analyticAccount.order)
          }
          if (value === 0) {
            value = compareStrings(
              elt1.analyticAccount.getLabel().toLowerCase(),
              elt2.analyticAccount.getLabel().toLowerCase()
            )
          }
          return value
        }
      )
    },
    depositTotal() {
      const expenses = sum(this.expenses.map(elt => elt.amount))
      const refunds = sum(this.refunds.map(elt => elt.amount))
      return sumPayments(this.payments) - expenses - refunds
    },
    hasReturned() {
      return this.payments.filter(elt => elt.returned).length > 0
    },
    sumReturned() {
      return sumPayments(this.payments.filter(elt => elt.returned))
    },
    depositTotalNoReturned() {
      const expenses = sum(this.expenses.map(elt => elt.amount))
      const refunds = sum(this.refunds.map(elt => elt.amount))
      return sumPayments(this.payments.filter(elt => !elt.returned)) - expenses - refunds
    },
    canDeletePayment() {
      // change et non pas delete
      return this.hasPerm('payments.change_payment')
    },
    paymentModes() {
      return distinct(this.payments.map(elt => elt.paymentMode))
    },
    paymentModeNames() {
      return this.paymentModes.map(elt => elt.name).join(' - ')
    },
  },
  mounted() {
    this.activeTab = this.tabs[0]
    this.areAnalyticsGrouped = window.localStorage.getItem(depositAnalyticsGroupedStorageKey) === 'yes'
    this.showDetail = window.localStorage.getItem(depositShowDetailStorageKey) === 'yes'
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadData(this.depositId)
    },
    onAnalyticsGroupedClick() {
      window.localStorage.setItem(depositAnalyticsGroupedStorageKey, this.areAnalyticsGrouped ? 'yes' : '')
    },
    onDetailsClick() {
      window.localStorage.setItem(depositShowDetailStorageKey, this.showDetail ? 'yes' : '')
    },
    getEntityLink(entity) {
      let path
      if (entity.family) {
        path = { name: 'families-detail', params: { entityId: '' + entity.id, }, }
      } else {
        path = { name: 'entities-detail', params: { entityId: '' + entity.id, }, }
      }
      return router.resolve(path).href
    },
    onDelete() {
      this.$bvModal.show('bv-confirm-modal:delete-deposit')
    },
    onUpdate() {
      this.$bvModal.show('bv-change-deposit-modal')
    },
    onPageChanged(event) {
      this.page = event.page
      this.loadElements(this.page)
    },
    loadData(depositId) {
      this.loadDeposit(depositId)
    },
    async loadDeposit(depositId) {
      this.startLoading(this.loadingName)
      let url = '/api/deposits/' + depositId + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.deposit = makeDeposit(resp.data)
        this.payments = resp.data.payments.map(elt => makePayment(elt))
        this.expenses = resp.data.expenses.map(elt => makeExpense(elt))
        this.refunds = resp.data.refunds.map(elt => makeRefund(elt))
        const elements = this.expenses.concat(this.refunds)
        for (const elt of elements) {
          for (const analytic of elt.analytics) {
            analytic.amount = -analytic.amount
          }
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async deleteDeposit(depositId) {
      this.startLoading(this.loadingName)
      let url = '/api/deposits/' + depositId + '/'
      const backendApi = new BackendApi('delete', url)
      try {
        const resp = await backendApi.callApi()
        await this.addSuccess('Le dépôt a été supprimé')
        router.push({ name: 'deposits', })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadDepositDocConfig() {
      if (this.payments.length) {
        const paymentMode = this.payments[0].paymentMode
        let url = '/api/deposit-doc-config/' + paymentMode.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.depositDocConfig = resp.data.length > 0
        } catch (err) {
          await this.addError(this.getErrorText(err))
          this.depositDocConfig = false
        }
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Excel',
          callback: this.toExcel,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.toPdf,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 3,
          label: 'Suppression',
          callback: this.onDelete,
          icon: 'fa fa-trash',
          cssClass: this.isLoading(this.loadingName) ? 'btn-danger disabled' : 'btn-danger',
        },
        {
          id: 4,
          label: 'Modifier',
          callback: this.onUpdate,
          icon: 'fa fa-edit',
          cssClass: this.isLoading(this.loadingName) ? 'btn-primary disabled' : 'btn-primary',
        }
      ]
    },
    async toExcel() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'deposit-excel' + this.deposit.id
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async toPdf() {
      await this.loadDepositDocConfig()
      let docUrl = ''
      let docSlug = ''
      let docContent = ''
      if (this.depositDocConfig && this.activeTab.name === 'payments') {
        docUrl = '/depot-bancaire/<key>/pdf/'
        docSlug = '' + this.deposit.id
      } else {
        docUrl = '/documents/standard/<key>/pdf/'
        docSlug = 'deposit-' + this.deposit.id
        docContent = this.$refs.printMe.innerHTML.toString()
      }
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    tabChanged(tab) {
      this.activeTab = tab
    },
    editPayment(payment) {
      this.editedPayment = payment
    },
    onEditPaymentHidden() {
      this.editedPayment = null
    },
    onRefresh() {
      // refresh
      this.onLoaded()
    },
    deletePayment(payment) {
      this.deletedPayment = payment
    },
    onDeletePaymentHidden() {
      this.deletedPayment = null
    },
    paymentInvoices(payment) {
      return distinct(payment.invoices)
    },
  },
}
</script>

<style scoped lang="less">
.number {
  text-align: right;
}
.inline {
  display: inline-block;
  margin-right: 20px;
}
.hover-me .show-on-hover {
  display: none;
}
.hover-me:hover .show-on-hover {
  display: inline-block;
  font-size: 13px;
  margin-right: 5px;
}
</style>
